.dropbtn1 {
  background-color: #f8f8f8;
  color: grey;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 25rem;
  justify-content: space-between;
  font-size: 1.3rem;
  margin: 0 0 0 -1rem;
  border: 1px solid grey;
  border-radius: 1rem;
}

/* The container <div> - needed to position the dropdown content */
.dropdown1 {
  position: relative;
  display: inline-block;
  margin: 0.85rem 0 0.75rem 1.25rem;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content1 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 18rem;
}

/* Change color of dropdown links on hover */
.dropdown-content1 a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown1:hover .dropdown-content1 {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown1:hover .dropbtn1 {
  background-color: transparent;
}

.input{
  background-color: #FFFFFF;  
backdrop-filter: blur(20px);
border: none;
color: #000000;
padding: 0 0 0 0;
font-size: 1.2rem;
}
.input:focus {
  border: none;
}
.input::placeholder{
  text-align: left;
  color: grey;
}

.input1{
background-color: #FFFFFF;  
backdrop-filter: blur(20px);
border: none;
color: #000000;
padding: 0 0 0 0.1rem;
font-size: 1.2rem;
}
.input1:focus {
border: none;
outline: none;
}
.input1::placeholder{
text-align: left;
color: grey;
padding: 0 0 0 0;
}

.countrySelect{
height: 3.75rem;
border: none;
}

.PhoneInputInput{
height: 2.5rem !important;
border: none !important;
}

@media only screen and (max-width: 1100px) {
  
.dropdown1 {
 
  margin: 0 ;
}
.dropbtn1 {

  margin: 0 0 0 1rem;
  

}


}

@media only screen and (max-width: 768px) {
  

.dropbtn1 {

  width: 13rem;
  

}


}
